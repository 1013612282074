import { css } from '@emotion/react';

export const MarketingButton = ({
    children = 'Get Started',
    type = 'primary',
    extraCSS,
    ...props
}: {
    children: React.ReactNode;
    type: 'primary' | 'secondary';
    extraCSS?: any;
}) => {
    return (
        <div className="flex justify-center" css={[buttonCSS, type === 'primary' ? primaryButtonCSS : secondaryCSS, extraCSS]}>
            {children}
        </div>
    );
};
const buttonCSS = css`
    font-size: 18px;
    height: 40px;
    padding: 10px 16px 8px 16px;
    width: fit-content;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    letter-spacing: 0.25px;

    cursor: pointer;

    :hover {
        filter: brightness(0.9);
    }
`;
const primaryButtonCSS = css`
    border-radius: 10px;
    background: linear-gradient(180deg, #5667ff 0%, #3145f9 100%);
    color: #fff;
`;
const secondaryCSS = css`
    border-radius: 10px;
    border: 1px solid #202124;
    background: #fff;
    color: #202124;
`;
