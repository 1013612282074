import { css } from '@emotion/react';
import Link from 'next/link';
import { useState } from 'react';

import { ImportPortfolioFlow } from '@/ui/container/portfolioConnect';

import { MarketingButton } from '@/marketing/components/common/button';
import { AppStoreButton, PlayStoreButton } from '@/marketing/components/homepage/hero/button';
import { CloseIcon, InvestmintLogo, MenuIcon } from '@/marketing/constants/icon';
import { CONTAINERS } from '@/marketing/constants/styles';
import { AnimatePresence, motion } from 'framer-motion';

const menuLinks = [
    {
        name: 'Features',
        link: '#features',
    },
    {
        name: 'Connected Portfolio',
        link: '#portfolio',
    },
    {
        name: 'Quant',
        link: '#quant',
        hideOnMobile: 'false',
    },
    {
        name: 'Reviews',
        link: '#reviews',
    },

    {
        name: 'Download',
        link: 'https://bit.ly/3LfQbvc',
        hideOnDesktop: 'true',
    },
    {
        name: 'Login',
        link: '/login',
        hideOnDesktop: 'true',
    },
];

export const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [showPortfolioConnect, setShowPortfolioConnect] = useState(false);

    return (
        <div>
            {showPortfolioConnect && (
                <ImportPortfolioFlow
                    onClose={() => {
                        setShowPortfolioConnect(false);
                    }}
                />
            )}

            <motion.div css={CONTAINERS.WIDTH_1320_16_PADDING}>
                <div className=" flex items-center justify-between py-24">
                    <motion.a href={'/'} whileHover={{ y: -2 }}>
                        <InvestmintLogo height={24} />
                    </motion.a>

                    <div
                        className={'absolute mt-4 flex gap-32 md:hidden'}
                        css={css`
                            left: 50%;
                            transform: translateX(-50%);
                        `}
                    >
                        {menuLinks.map((item) =>
                            item.hideOnDesktop ? null : (
                                <a
                                    href={item.link}
                                    css={linkCSS}
                                    className={'text-20 text-black-100'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const hash = item.link.split('#')[1];
                                        if (hash) {
                                            const el = document.getElementById(hash);
                                            el.scrollIntoView({ behavior: 'smooth' });
                                        } else {
                                            window.location.href = item.link;
                                        }
                                    }}
                                >
                                    {item.name}
                                </a>
                            ),
                        )}
                    </div>

                    <div className="flex gap-8 md:hidden">
                        <div
                            onClick={() => {
                                setShowPortfolioConnect(true);
                            }}
                        >
                            <MarketingButton type="primary">Connect Portfolio</MarketingButton>
                        </div>
                        <Link href="/login">
                            <MarketingButton type="secondary">Login</MarketingButton>
                        </Link>
                    </div>

                    <div css={[zIndex, iconCSS]} className="  md:visible" onClick={setIsOpen.bind(this, !isOpen)}>
                        {!isOpen ? <MenuIcon /> : <CloseIcon />}
                    </div>
                    <MobileNavBar isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
            </motion.div>
        </div>
    );
};

export const MobileNavBar = ({ isOpen, setIsOpen }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className={'flex flex-col justify-between p-24 py-20 pr-16'}
                    initial={{ opacity: 1, left: 500, position: 'fixed' }}
                    animate={{ opacity: 1, left: 0, position: 'fixed' }}
                    exit={{ opacity: 1, left: 500, position: 'fixed' }}
                    transition={{
                        // ease: "linear",
                        duration: 0.2,
                        // x: { duration: 1 }
                    }}
                    css={mobileNavbar}
                >
                    <div>
                        <div className={'flex items-center justify-between'} onClick={setIsOpen.bind(this, false)}>
                            <div className={'text-22 font-600'}>Menu</div>
                        </div>

                        <div className={'mt-46 flex flex-col gap-28'}>
                            {menuLinks.map((item) =>
                                item.hideOnMobile ? null : (
                                    <a
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                        href={item.link}
                                        css={mobileLinkCSS}
                                        className={'text-18 font-500'}
                                    >
                                        {item.name}
                                    </a>
                                ),
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col gap-12 pb-40 ">
                        <PlayStoreButton />
                        <AppStoreButton />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const iconCSS = css`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const mobileNavbar = css`
    height: 102vh;
    width: 100vw;
    background: #ffffff;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 100;
`;

const zIndex = css`
    z-index: 4000;
`;
const linkCSS = css`
    :hover {
        text-decoration: underline;
        color: #4558fc;
    }
`;

const mobileLinkCSS = css`
    margin-top: 0px;
    border-radius: 10px;
`;
